import { undoable, isAnyField, withBi } from '../utils'
import { ComponentRef, ColorPalette } from '../api-types'
import * as _ from 'lodash'
import CoreApi from '../core-api'
import { getStyleValues, calcFieldsCommonStyle } from '../services/form-style-service'
import { getTheme } from '../preset/preset-styles'
import { Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'
import { FormStyle } from '../../../constants/form-style'
import { EVENTS } from '../../../constants/bi'

const paletteToMatrix = palette => {
  const result = [[], [], [], [], []]

  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 5; j++) {
      result[i][j] = palette[`color_${i * 5 + j + 11}`]
    }
  }

  return result
}
export default class StyleApi {
  private boundEditorSDK: any
  private editorSDK: any
  private coreApi: CoreApi
  private biLogger: any

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.editorSDK = editorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateFieldsStyle(
    componentRef: ComponentRef,
    { styleName, newStyleValue }: { styleName: FormStyle; newStyleValue: string },
    _biData
  ) {
    const fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes: true,
    })

    return Promise.all(
      fields.map(({ componentRef }) =>
        this._updateCompStyle(componentRef, { styleName, newStyleValue })
      )
    )
  }

  public async getColorsPalette(): Promise<ColorPalette> {
    const palette = await this.boundEditorSDK.theme.colors.getAll()
    return { colorsPalette: paletteToMatrix(palette), colors: palette }
  }

  public openColorPicker(color, options) {
    return this.editorSDK.editor.openColorPicker(color, options)
  }

  public getFontsOptions() {
    return this.boundEditorSDK.fonts.getFontsOptions()
  }

  public getThemedFonts() {
    return this.boundEditorSDK.theme.fonts.getMap()
  }

  public async getFieldsCommonStyles(componentRef: ComponentRef) {
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const childrenRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const children = await this.boundEditorSDK.components.get({
      componentRefs: childrenRefs,
      properties: ['style', 'data', 'connections'],
    })
    const fields = _.flatMap(
      children,
      ({ connections, data, style }) =>
        isAnyField(_.get(connections, '[0].role'))
          ? {
              compType: data.type,
              style: style.style.properties,
            }
          : []
    )
    return calcFieldsCommonStyle(fields)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateTheme(componentRef: ComponentRef, theme: Theme, _biData) {
    await this.coreApi.setComponentConnection(componentRef, { theme })
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const children = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const stylesByRole = getTheme(theme)

    return Promise.all(
      _.map(children, async (childRef: ComponentRef) => {
        const { role } = await this.coreApi.getComponentConnection(childRef)
        const style = stylesByRole[role]
        if (!style) {
          return
        }
        if (!_.isString(style)) {
          return this.boundEditorSDK.components.style.update({ componentRef: childRef, style })
        }
        const { text } = await this.boundEditorSDK.components.data.get({ componentRef: childRef })
        const newText = _.replace(style, 'TITLE', innerText(text))
        return this.boundEditorSDK.components.data.update({
          componentRef: childRef,
          data: { text: newText },
        })
      })
    )
  }

  private async _updateCompStyle(componentRef: ComponentRef, { styleName, newStyleValue }) {
    const {
      style: { properties },
    } = await this.boundEditorSDK.components.style.get({
      componentRef,
    })
    return this.boundEditorSDK.components.style.update({
      componentRef,
      style: _.merge({}, properties, getStyleValues(styleName, newStyleValue)),
    })
  }
}

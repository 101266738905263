import { bindObjectFunctions } from '../../utils/utils'
import translations from '../core/services/translations'
import Experiments from 'wix-experiments'
import * as createWixCodeCollectionsApi from 'wix-code-collections-api'
import * as createWixCodeCollectionPermissionsApi from 'wix-code-collections-permissions-api'
import * as createEditorTransportLayer from 'wix-code-collections-transport-editor'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import { ORIGINS } from '../../constants/bi'
import * as Raven from 'raven-js'
import RemoteApi from '../../panels/commons/remote-api'
import { FormPreset } from '../../constants/form-types'
import CoreApi from '../core/core-api'
import { createPanelDefs } from '../core/services/panel-definitions'
import { setBiLogger, setPanelDefinitions } from '../editor-app'
import { addForm, openAddFormPanel } from '../core-actions'
import { fetcher } from '../utils/utils'

const EDITOR_INITIATOR = 'EDITOR'

const fetchCoreApi = fetcher()
export const getApi = () => fetchCoreApi.getData // race-condition editorReady & onEvent

export const startApp = (withPreset, origin) => {
  if (!origin) {
    return
  }
  if (withPreset) {
    addForm(origin.info.preset, {
      coords: origin.info.coords,
      theme: origin.info.theme,
      source_name: ORIGINS.ADD_PANEL,
    })
  } else if (origin.info.type === FormPreset.REGISTRATION_FORM) {
    addForm(FormPreset.REGISTRATION_FORM, { targetPageRef: origin.info.targetPageRef })
  } else if (origin.initiator === EDITOR_INITIATOR) {
    return openAddFormPanel()
  }
}

export const initApp = async ({ appDefinitionId, editorSDK, msid }) => {
  const { coreApi, boundEditorSDK, biLogger, experiments } = await initCoreApi({
    appDefinitionId,
    editorSDK,
    msid,
  })

  const [locale] = await Promise.all([
    boundEditorSDK.environment.getLocale(),
    boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi()),
  ])
  experiments.load('wix-form-builder')

  await translations.editorInit(locale)

  const panelDefinitions = createPanelDefs(msid, experiments)

  setBiLogger(biLogger)
  setPanelDefinitions(panelDefinitions)
  coreApi.premium.setPremiumState()
  fetchCoreApi.resolveData(coreApi)
}

const initCoreApi = async ({ appDefinitionId, editorSDK, msid }) => {
  const boundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const experiments = new Experiments({ baseUrl: 'https://www.wix.com' })
  const transportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  const collectionsApi = createWixCodeCollectionsApi({
    transportLayer,
    pluginId: appDefinitionId,
  })
  const collectionsPermissionsApi = createWixCodeCollectionPermissionsApi({
    editorSDK,
    token: appDefinitionId,
  })
  const appInstance = await boundEditorSDK.info.getAppInstance()
  const remoteApi = new RemoteApi({ boundEditorSDK, appDefinitionId, appInstance })
  const biLogger = await initBiLoggerForEditorApp(msid)

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      collectionsPermissionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance: Raven,
    biLogger,
  })
  return { coreApi, boundEditorSDK, biLogger, experiments }
}

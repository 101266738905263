import { FieldPreset } from '../../../../constants/field-types'

export default (buttonLabel, label) => ({
  [FieldPreset.GENERAL_UPLOAD_BUTTON]: buttonLabel,
  [FieldPreset.GENERAL_RADIO_BUTTON]: label,
  [FieldPreset.GENERAL_CHECKBOX]: label,
  [FieldPreset.GENERAL_SINGLE_CHECKBOX]: label,
  [FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: label,
  [FieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: label,
})

import * as _ from 'lodash'

export const isProduction = () => (process.env.NODE_ENV || '') !== 'development'

export const bindObjectFunctions = (editorSDK, token) => {
  return _.mapValues(editorSDK, val => {
    if (_.isFunction(val)) {
      return val.bind(editorSDK, token)
    } else if (_.isPlainObject(val)) {
      return bindObjectFunctions(val, token)
    }

    return val
  })
}

export function getStackTrace() {
  try {
    throw new Error()
  } catch (e) {
    return e.stack.toString() || ''
  }
}

export function getStackTraceSSR() {
  const fakeError = new Error()
  return fakeError.stack ? fakeError.stack.toString() : ''
}

export const getAppUrl = (origin = 'editor-app'): string =>
  _.head(
    (origin === 'viewer-app' ? getStackTraceSSR() : getStackTrace()).match(
      RegExp(`https?://.*?${origin}.*?.js`)
    )
  ) || ''

export const getBaseUrl = () => {
  const url: string = _.head(getAppUrl().match(/((?:\/[^\/]+)+)(?=\/[^\/]+)/))
  return `${(url && url.substring(1)) || ''}`
}

export const getAppVersionFromUrl = (origin = 'editor-app') => {
  const version = /\bv?(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)(?:-[\da-z-]+(?:\.[\da-z-]+)*)?(?:\+[\da-z-]+(?:\.[\da-z-]+)*)?\b/gi.exec(
    getAppUrl(origin)
  )
  return version ? version[0] : '0.0.0'
}

export const getStaticsBaseUrl = () => `${getBaseUrl()}/statics`

export const isLocalhost = (origin = 'editor-app') =>
  /https?:\/\/localhost/.test(origin === 'editor-app' ? getStackTrace() : getStackTraceSSR())

export const innerText = str => str.replace(/\s*<[^>]*>\s*/gm, '')

export const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') //eslint-disable-line no-useless-escape

export const getSentryDSN = (origin = 'editor-app') =>
  isLocalhost(origin) ? null : 'https://956b313ecaf54bafa205bc7592d2dc07@sentry.io/291659'

export const safelyStringify = obj => {
  // taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
  const seen = []
  try {
    return JSON.stringify(obj, (_key, value) => {
      if (value != null && typeof value === 'object') {
        if (seen.indexOf(value) >= 0) {
          return
        }
        seen.push(value)
      }
      return value
    })
  } catch (err) {
    return ''
  }
}

// https://github.com/sindresorhus/serialize-error/
export const serializeError = value => {
  if (typeof value === 'object') {
    return safelyStringify(value)
  }
  // People sometimes throw things besides Error objects, so…
  if (typeof value === 'function') {
    // JSON.stringify discards functions. We do too, unless a function is thrown directly.
    return `[Function: ${value.name || 'anonymous'}]`
  }
  return value
}

export const createSuffixedName = (names, name, separator = ' ') => {
  if (_.includes(names, name)) {
    let suffix = 2
    while (_.includes(names, `${name}${separator}${suffix}`)) {
      suffix++
    }
    return `${name}${separator}${suffix}`
  }
  return name
}

export const isWixEmployeeEmail = email => {
  return _.endsWith(email, '@wix.com')
}

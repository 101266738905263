import * as _ from 'lodash'
import { FIELDS } from '../../../../../../constants/roles'
import { FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { makeGeneralText } from '../../general-fields/definitions/general-text'
import { Field } from '../../field-types-data'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

export const makeLoginEmail = (): Field => {
  const t = translations.t.bind(translations)

  return _.merge(makeGeneralText(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL,
      connectionConfig: {
        crmType: CRM_TYPES.EMAIL,
        crmTag: CRM_TAGS.OTHER,
        crmLabel: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_LOGIN_EMAIL}`),
      },
      data: {
        textType: 'email',
      },
      props: {
        placeholder: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_LOGIN_EMAIL}`),
        required: true,
      },
    },
  })
}

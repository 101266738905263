import * as _ from 'lodash'
import { FONT_STYLE_VALUE_PREFIX, FormStyle } from '../../../constants/form-style'

const COMMON_STYLES_MAPPING = {
  [FormStyle.INPUT_BG_COLOR]: {
    primary: 'bg',
    default: 'color_11',
    getStyleParams: primaryColor => ({
      bg: primaryColor,
      bgf: primaryColor,
      bgh: primaryColor,
    }),
  },
  [`alpha-${FormStyle.INPUT_BG_COLOR}`]: {
    primary: 'alpha-bg',
    default: '1',
    getStyleParams: alpha => ({
      'alpha-bg': alpha,
      'alpha-bgf': alpha,
      'alpha-bgh': alpha,
    }),
  },
  [FormStyle.INPUT_BORDER_COLOR]: {
    primary: 'brd',
    default: 'color_15',
    getStyleParams: primaryColor => ({
      brd: primaryColor,
      btn_brd: primaryColor, //eslint-disable-line camelcase
      brdh: primaryColor,
      brdf: primaryColor,
    }),
  },
  [`alpha-${FormStyle.INPUT_BORDER_COLOR}`]: {
    primary: 'alpha-brd',
    default: '0.55',
    getStyleParams: alpha => ({
      'alpha-brd': alpha,
      'alpha-btn_brd': alpha,
      'alpha-brdh': alpha,
      'alpha-brdf': alpha,
    }),
  },
  [FormStyle.TEXT_COLOR]: {
    primary: 'txt',
    default: 'color_15',
    getStyleParams: primaryColor => {
      const clr = { primary: primaryColor, secondary: primaryColor }
      if (/^color_(\d)+$/.test(primaryColor)) {
        clr.secondary = `color_${parseInt(/(\d)+$/g.exec(primaryColor)[0]) - 1}`
      }
      return {
        txt: clr.primary,
        txt2: clr.secondary,
        'txt-placeholder': clr.secondary,
      }
    },
  },
  [FormStyle.FONT]: {
    primary: 'fnt',
    default: 'font_8',
    getStyleParams: primaryFnt => {
      const font =
        _.includes(primaryFnt, FONT_STYLE_VALUE_PREFIX) || /^font_(\d)+$/.test(primaryFnt)
          ? primaryFnt
          : `${FONT_STYLE_VALUE_PREFIX}${primaryFnt.split(' ').join('+')}`
      return {
        fnt: font,
        fnt2: font,
        btn_fnt: font, //eslint-disable-line camelcase
      }
    },
  },
  [FormStyle.BORDER_WIDTH]: {
    primary: 'brw',
    default: '1',
    getStyleParams: border => ({
      brw: border,
      brwe: border,
      brwd: border,
      brwf: border,
      brwh: border,
      btn_brw: border, //eslint-disable-line camelcase
    }),
  },

  [FormStyle.BORDER_RADIUS]: {
    primary: 'rd',
    default: '0',
    getStyleParams: radius => ({
      rd: `${radius}px`,
    }),
  },
}

export const getFieldCompStyleProps = commonStyles => {
  return _.reduce(
    COMMON_STYLES_MAPPING,
    (result, styleConfig, styleName) =>
      _.assign(result, styleConfig.getStyleParams(commonStyles[styleName])),
    {}
  )
}

export const calcFieldsCommonStyle = fields =>
  _.reduce(
    COMMON_STYLES_MAPPING,
    (result, styleConfig, styleName) => {
      const cache = _.reduce(
        fields,
        (c, field) => {
          const paramValue = field.style[styleConfig.primary] || styleConfig.default
          const e = _.find(c, { paramValue })
          if (e) {
            e.count++
          } else {
            c.push({ paramValue, count: 1 })
          }
          return c
        },
        []
      )
      const majorityElement = <any>_.maxBy(cache, 'count')
      const styleValue = majorityElement ? majorityElement.paramValue : styleConfig.default
      const commonStyle = { [styleName]: styleValue }
      return { ...result, ...commonStyle }
    },
    {}
  )

export const getStyleValues = (styleName, newStyleValue) =>
  COMMON_STYLES_MAPPING[styleName].getStyleParams(newStyleValue)

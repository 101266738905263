export enum FormStyle {
  INPUT_BG_COLOR = 'INPUT_BG_COLOR',
  ALPHA_INPUT_BG_COLOR = 'alpha-INPUT_BG_COLOR',
  INPUT_BORDER_COLOR = 'INPUT_BORDER_COLOR',
  ALPHA_INPUT_BORDER_COLOR = 'alpha-INPUT_BORDER_COLOR',
  TEXT_COLOR = 'TEXT_COLOR',
  FONT = 'FONT',
  BORDER_WIDTH = 'BORDER_WIDTH',
  BORDER_RADIUS = 'BORDER_RADIUS',
}

export const FONT_STYLE_VALUE_PREFIX = 'normal normal normal 15px/1.4em '

export enum Theme {
  THEME01 = 'theme01',
  THEME02 = 'theme02',
  THEME03 = 'theme03',
  THEME04 = 'theme04',
  THEME05 = 'theme05',
  THEME06 = 'theme06',
}

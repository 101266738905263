export const preset = t => ({
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 515,
        height: 64,
        x: 57,
        y: 1,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jiiv9k9n',
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: {
          isPreset: true,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f10: 'font_10',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f10: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'title_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        brightness: 1,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        text: `<h5 class='font_5'><span style='font-size:30px;'>${t(
          'preset.registrationFormTitleText'
        )}</span></h5>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 65,
        y: 175,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-j9445bid',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jiiv04dl',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '0.9',
            bg: 'color_11',
            bge: '#fbf3f3',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#f60419',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            brwd: '1',
            brwe: '1',
            brwf: '1',
            brwh: '1',
            btn_brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#E8E8E8',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'value',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txt2: 'theme',
            txtd: 'value',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmType: 'firstName',
        crmLabel: t('preset.firstNameCrmLabel'),

        fieldType: 'firstName',
        collectionFieldType: 'text',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.firstNameCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'text',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 340,
        y: 175,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-j9445bid',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jiiuzutk',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '0.9',
            bg: 'color_11',
            bge: '#fbf3f3',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#f60419',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            brwd: '1',
            brwe: '1',
            brwf: '1',
            brwh: '1',
            btn_brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#E8E8E8',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'value',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txt2: 'theme',
            txtd: 'value',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmType: 'lastName',
        crmLabel: t('preset.lastNameCrmLabel'),

        fieldType: 'lastName',
        collectionFieldType: 'text',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.lastNameCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'text',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 65,
        y: 249,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmp6vd2',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jiiuy7af',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '0.9',
            bg: 'color_11',
            bge: '#fbf3f3',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#f60419',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            brwd: '1',
            brwe: '1',
            brwf: '1',
            brwh: '1',
            btn_brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#E8E8E8',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'value',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txt2: 'theme',
            txtd: 'value',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_regForm_loginEmail',
      config: {
        crmLabel: t('preset.emailCrmLabel'),

        fieldType: 'email',
        crmType: 'email',
        crmTag: 'other',
        collectionFieldKey: 'email',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: true,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.emailCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'email',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 340,
        y: 249,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmp6v62',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jiiv0ex0',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '1',
            'alpha-brdf': '1',
            'alpha-brdh': '0.9',
            bg: 'color_11',
            bge: '#fbf3f3',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#f60419',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            brwd: '1',
            brwe: '1',
            brwf: '1',
            brwh: '1',
            btn_brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#E8E8E8',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-bgd': 'value',
            'alpha-bge': 'value',
            'alpha-bgf': 'value',
            'alpha-bgh': 'value',
            'alpha-brd': 'value',
            'alpha-brde': 'value',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'value',
            bgf: 'theme',
            bgh: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brdd: 'value',
            brde: 'value',
            brdf: 'theme',
            brdh: 'theme',
            brw: 'value',
            brwd: 'value',
            brwe: 'value',
            brwf: 'value',
            brwh: 'value',
            btn_brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txt2: 'theme',
            txtd: 'value',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_regForm_password',
      config: {
        crmLabel: t('preset.passwordCrmLabel'),

        fieldType: 'password',
        crmType: 'customField',
        collectionFieldKey: 'password',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: true,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.passwordCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'password',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.button.BasicButton',
      layout: {
        width: 142,
        height: 40,
        x: 245,
        y: 402,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.SiteButton',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jiivduji',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brdh': '0',
            'alpha-txt': '1',
            'alpha-txth': '1',
            bg: 'color_23',
            bgh: 'color_17',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_14',
            brdh: 'color_12',
            brw: '0',
            fnt: 'normal normal normal 18px/1.4em futura-lt-w01-book',
            rd: '300px 300px 300px 300px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_11',
            txth: 'color_11',
          },
          propertiesSource: {
            'alpha-brdh': 'value',
            bg: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brdh: 'theme',
            brw: 'value',
            fnt: 'value',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txth: 'theme',
          },
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.SiteButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
      },
      role: 'button_Role',
      config: {},
      props: {
        type: 'ButtonProperties',
        metaData: {
          schemaVersion: '1.0',
        },
        align: 'center',
        margin: 0,
      },
      data: {
        type: 'LinkableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        label: t('preset.submitButtonLabel'),

        link: {
          type: 'FormSubmitButtonLink',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  layout: {
    width: 647,
    height: 521,
    x: 166,
    y: 230,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.FormContainer',
  role: 'form_Role',
  config: {
    preset: 'registration-form',
    labels: ['contacts-contacted_me'],
    emailId: '',
    plugins: [{ id: 'registrationForm' }],
  },
})

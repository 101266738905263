import { undoable, withBi, isInputField, isAnyField } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, FormField } from '../api-types'
import * as _ from 'lodash'
import CoreApi from '../core-api'
import { ROLE_FORM, FIELDS_ROLES, ROLE_SUBMIT_BUTTON, ROLE_MESSAGE } from '../../../constants/roles'
import { FieldPreset, FieldBehaviorType } from '../../../constants/field-types'
import { FormPreset } from '../../../constants/form-types'
import { createSuffixedName } from '../../../utils/utils'
import { createField } from '../services/form-service'
import questions from './consts/questions'
import { MOBILE_CONTAINER, GROUP_COMPONENT } from './consts/container-types'

export default class FeildSettingsApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: any

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
  }

  private async _getAncestorsWithTypes(
    componentRef,
    types: string[]
  ): Promise<{ type: string; componentRef: ComponentRef }[]> {
    const anscestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
    const ancestorsWithTypes = await Promise.all<{ type: string; componentRef: ComponentRef }>(
      anscestors.map(async (componentAncestor: ComponentRef) => ({
        type: await this.boundEditorSDK.components.getType({ componentRef: componentAncestor }),
        componentRef: componentAncestor,
      }))
    )

    return ancestorsWithTypes.filter(ancestor => types.some(type => ancestor.type === type))
  }

  private async _sumOffsets(containers: ComponentRef[]) {
    const containersOffset = await containers.reduce(
      async (offsetAccumulatorPromise, currentValue) => {
        const offsetAccumulator = await offsetAccumulatorPromise
        const containerLayout = await this.boundEditorSDK.components.layout.get({
          componentRef: currentValue,
        })

        return {
          x: offsetAccumulator.x + containerLayout.x,
          y: offsetAccumulator.y + containerLayout.y,
        }
      },
      Promise.resolve({ x: 0, y: 0 })
    )

    return containersOffset
  }

  public async getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false }
  ) {
    const getRawFields = async () => {
      const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
      const children = await this.boundEditorSDK.controllers.listConnectedComponents({
        controllerRef,
      })
      const rawFieldsPromises: Array<Promise<FormField>> = children.map(child =>
        this.getField(child, allFieldsTypes)
      )
      return Promise.all(rawFieldsPromises)
    }

    const rawFields = await getRawFields()
    const fields = await Promise.all(
      rawFields.filter(x => !!x).map(async field => {
        const parentContainers = await this._getAncestorsWithTypes(field.componentRef, [
          MOBILE_CONTAINER,
          GROUP_COMPONENT,
        ])
        const fieldLayout = (await this.boundEditorSDK.components.layout.get({
          componentRef: field.componentRef,
        })) || { x: 0, y: 0, height: 0, width: 0 }

        if (parentContainers.length > 0) {
          const containersOffset = await this._sumOffsets(
            parentContainers.map(container => container.componentRef)
          )

          fieldLayout.x += containersOffset.x
          fieldLayout.y += containersOffset.y
        }

        const { x, y, height, width } = fieldLayout
        return _.merge({ x, y, height, width }, field)
      })
    )
    return _.sortBy(fields, ['y', 'x'])
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async updateCrmLabel(componentRef: ComponentRef, crmLabel: string, _biData = {}) {
    const {
      config: { collectionFieldKey },
      controllerRef,
    } = await this.coreApi.getComponentConnection(componentRef)
    await this.coreApi.setComponentConnection(componentRef, { crmLabel })

    const updateCollection = async () => {
      const collectionId = await this._getCollectionId(controllerRef)
      if (!collectionId) {
        return
      }
      return this.coreApi.collectionsApi.updateField(collectionId, collectionFieldKey, crmLabel)
    }

    return updateCollection()
  }

  @undoable()
  public changeQuestion(componentRef: ComponentRef, question, fieldType) {
    switch (fieldType) {
      case FieldPreset.GENERAL_UPLOAD_BUTTON:
        return this._changeUploadFileLabel(componentRef, question)
      case FieldPreset.GENERAL_RADIO_BUTTON:
      case FieldPreset.GENERAL_CHECKBOX:
      case FieldPreset.GENERAL_SINGLE_CHECKBOX:
      case FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS:
        return this._changeLabel(componentRef, question)
      default:
        return this._changePlaceholder(componentRef, question)
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.TOGGLE_REQUIRED_FIELD })
  public changeRequired(componentRef: ComponentRef, required: boolean, _biData = {}) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { required },
    })
  }

  @undoable()
  public updatedCheckedByDefault(componentRef, checked) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { checked } })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.SELECT_FIELD_TO_CONNECT })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getCustomFields() {
    return this.remoteApi.getCustomFields()
  }

  public createCustomField(field) {
    return this.remoteApi.createCustomField(field)
  }

  public async getField(
    componentRef: ComponentRef,
    allFieldsTypes: boolean = false
  ): Promise<FormField> {
    const {
      config: {
        crmLabel,
        crmType,
        crmTag,
        customFieldId,
        fieldType,
        collectionFieldKey,
        collectionFieldType,
      },
      role,
    } = await this.coreApi.getComponentConnection(componentRef)

    const isValidFieldPred: (role: string) => boolean = allFieldsTypes ? isAnyField : isInputField
    if (!isValidFieldPred(role)) {
      return null
    }

    const [
      {
        props: { placeholder, required },
        data: { buttonLabel, label, checked },
      },
    ] = await this.boundEditorSDK.components.get({
      componentRefs: componentRef,
      properties: ['props', 'data'],
    })

    const question = questions(buttonLabel, label)[fieldType] || placeholder
    return {
      question,
      componentRef,
      crmLabel,
      required,
      crmType,
      crmTag,
      fieldType,
      customFieldId,
      collectionFieldKey,
      collectionFieldType,
      checked,
      role,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DUPLICATE_FIELD })
  public async duplicateField(
    componentRef: ComponentRef,
    field: FormField,
    preset: FormPreset,
    { extraData, commonStyles },
    _biData = {}
  ) {
    const [
      {
        style,
        data,
        layout: { height, width },
      },
    ] = await this.boundEditorSDK.components.get({
      componentRefs: [field.componentRef],
      properties: ['style', 'data', 'layout'],
    })
    const { connectToRef, controllerRef } = await this._addField(componentRef, preset, {
      fieldType: field.fieldType,
      extraData: _.merge({}, extraData, {
        data,
        layout: { height, width },
      }),
      commonStyles,
    })
    await this.boundEditorSDK.components.style.update({
      componentRef: connectToRef,
      style: style.style.properties,
    })
    return { connectToRef, controllerRef }
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD,
    endEvid: EVENTS.PANELS.addFieldPanel.ADD_FIELD_COMPLETE,
  })
  public async addField(
    componentRef: ComponentRef,
    preset: FormPreset,
    { fieldType, extraData, commonStyles, fieldBehaviorType = FieldBehaviorType.INPUT },
    _biData = {}
  ) {
    return this._addField(componentRef, preset, {
      fieldType,
      extraData,
      commonStyles,
      fieldBehaviorType,
    })
  }

  private async _findNewFieldLayout(componentRef: ComponentRef) {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(componentRef, FIELDS_ROLES)
    const lastLayout: any = _.maxBy(childLayouts, (field: any) => field.y)
    return {
      x: lastLayout ? lastLayout.x : 60,
      y: lastLayout ? lastLayout.y + lastLayout.height + 32 : 60,
    }
  }

  private async _addField(
    componentRef: ComponentRef,
    preset,
    { fieldType, extraData, commonStyles, fieldBehaviorType = FieldBehaviorType.INPUT }
  ) {
    const {
      controllerRef,
      config: { collectionId, theme },
    } = await this.coreApi.getComponentConnection(componentRef)
    const layout = await this._findNewFieldLayout(componentRef)
    const fields = await this.getFieldsSortByXY(componentRef)
    if (fieldBehaviorType === FieldBehaviorType.INPUT) {
      const collectionFieldKey = createSuffixedName(
        _.map(fields, 'collectionFieldKey'),
        _.camelCase(_.get(extraData, 'connectionConfig.crmLabel')),
        ''
      )
      _.set(extraData, 'connectionConfig.collectionFieldKey', collectionFieldKey)
    }
    const field = createField(preset, { fieldType, extraData, commonStyles, theme }, layout)
    const { connectToRef } = await this.coreApi.addComponentAndConnect(
      field,
      controllerRef,
      componentRef
    )
    const fieldLayout = await this.boundEditorSDK.components.layout.get({
      componentRef: connectToRef,
    })

    const submitBtn = async () => {
      const buttons = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_SUBMIT_BUTTON)
      const submitBtn: any = buttons[0]
      return submitBtn
        ? this.boundEditorSDK.components.layout.update({
            componentRef: submitBtn.componentRef,
            layout: { y: submitBtn.y + fieldLayout.height + 32 },
          })
        : null
    }
    const boxLayout = async boxRef => {
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef: boxRef })
      return this.boundEditorSDK.components.layout.update({
        componentRef: boxRef,
        layout: { height: boxLayout.height + fieldLayout.height + 32 },
      })
    }
    const message = async () => {
      const messages = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_MESSAGE)
      const message: any = messages[0]
      return message
        ? this.boundEditorSDK.components.layout.update({
            componentRef: message.componentRef,
            layout: { y: message.y + fieldLayout.height + 32 },
          })
        : null
    }

    await Promise.all([
      this.coreApi.collectionsApi.addFieldToCollection(collectionId, field.connectionConfig),
      submitBtn(),
      this.boundEditorSDK.components
        .getAncestors({ componentRef })
        .then(ancestors => Promise.all(_.map(ancestors, boxLayout))),
      boxLayout(componentRef),
      message(),
    ])

    return { connectToRef, controllerRef }
  }

  private _changePlaceholder(componentRef: ComponentRef, placeholder) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { placeholder },
    })
  }

  private _changeUploadFileLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { buttonLabel },
    })
  }

  private _changeLabel(componentRef: ComponentRef, label) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label },
    })
  }

  private async _getCollectionId(controllerRef) {
    const componentRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    if (!componentRef) {
      return
    }
    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return collectionId
  }
}

import { getStaticsBaseUrl } from '../../../utils/utils'
import translations from './translations'
import { GFPP_ACTIONS } from '../manifests/manifest-commons'

const createUrlFor = msid => panelName =>
  `https://${getStaticsBaseUrl()}/${panelName}-panel.html?msid=${msid}`

export const createPanelDefs = (msid, experiments) => {
  const urlFor = createUrlFor(msid)

  return {
    addFormPanel: () => ({
      title: translations.t('addForm.panelTitle'),
      width: 666,
      height: 508,
      helpId: 'e5d2c2d0-8f70-4140-b6ef-613327dbe3a8',
      url: urlFor('add-form'),
    }),
    settingsPanel: (componentRef, initialData) => ({
      title: translations.t('settings.panelTitle'),
      componentRef,
      initialData,
      width: 305,
      height: 546,
      type: GFPP_ACTIONS.SETTINGS,
      helpId: '82b1e9b4-4e92-42c9-b414-2e4a0200e97f',
      url: urlFor('settings'),
    }),
    manageFieldsPanel: (componentRef, initialData, mode) => ({
      title: translations.t('manageFields.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 546,
      helpId: {
        desktop: '357699f6-91ef-481a-9daa-14b5f029239c',
        mobile: 'f6d126a0-108c-4157-98f7-74dac3ac067e',
      }[mode],
      url: urlFor('manage-fields'),
    }),
    fieldSettingsPanel: (componentRef, initialData, mode) => ({
      title: translations.t('fieldSettings.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 440,
      helpId: {
        desktop: '9daa29ec-1cda-460e-a855-4a43858af5fb',
        mobile: 'dad84a79-8701-408c-9174-454062221932',
      }[mode],
      url: urlFor('field-settings'),
    }),
    formStylePanel: (componentRef, initialData, mode) => ({
      title: !experiments.enabled('specs.cx.FormBuilderDiscoverDesigns')
        ? translations.t('formStyle.panelTitleOld')
        : translations.t('formStyle.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 444,
      type: GFPP_ACTIONS.DESIGN,
      helpId: {
        desktop: '2e7088dd-c331-44b6-8e43-180c136ee254',
        mobile: '8e0960f1-0647-4e6f-b71f-00457133c153',
      }[mode],
      url: urlFor('form-style'),
    }),
    formLayoutPanel: (componentRef, initialData, mode) => ({
      title: translations.t('formStyle.layout.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 156,
      type: GFPP_ACTIONS.LAYOUT,
      helpId: {
        desktop: '69c55c8d-6865-45ed-ab87-7ce70e1564f9',
        mobile: '7a4ec10c-f40f-4ebf-a475-0fbdd3978ad6',
      }[mode],
      url: urlFor('form-layout'),
    }),
    buttonChangeTextPanel: (componentRef, initialData) => ({
      title: translations.t('buttonChangeText.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 176,
      url: urlFor('button-change-text'),
    }),
    addFieldPanel: (componentRef, initialData) => ({
      title: translations.t('addField.panelTitle'),
      initialData,
      componentRef,
      width: 430,
      height: 519,
      url: urlFor('add-field'),
    }),
    upgradeAlertPanel: (componentRef, initialData) => ({
      shouldHideHeader: true,
      initialData,
      componentRef,
      width: 564,
      height: 306,
      url: urlFor('upgrade-alert'),
    }),
  }
}

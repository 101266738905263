import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import { getAppVersionFromUrl, getSentryDSN } from '../utils/utils'
import { getAppManifest } from './core/manifests/app-manifest'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import { EDITOR_EVENTS } from './constans/EVENTS'
import { initApp, startApp } from './editor-ready/editor-ready'
import {
  handleGfppClicked,
  handleComponentDelete,
  handlePublish,
  handleFirstSave,
} from './on-event/on-event'
import { fetcher } from './utils/utils'

Raven.config(getSentryDSN(), {
  logger: 'logger-editor-app',
  release: getAppVersionFromUrl(),
})

let biLogger
let panelDefinitions: any = {}
let isInitialized = false

const fetchSiteId = fetcher()
export const getSiteId = () => fetchSiteId.getData
export const getBiLogger = () => biLogger
export const getPanelDefinitions = () => panelDefinitions
export const setBiLogger = logger => {
  biLogger = logger
}
export const setPanelDefinitions = definitions => {
  panelDefinitions = definitions
}

const onEvent = ({ eventType, eventPayload }: { eventType: EDITOR_EVENTS; eventPayload: any }) => {
  switch (eventType) {
    case EDITOR_EVENTS.GFPP_CLICKED:
      handleGfppClicked(eventPayload)
      break
    case EDITOR_EVENTS.COMPONENT_DELETED:
      handleComponentDelete(eventPayload)
      break
    case EDITOR_EVENTS.SITE_PUBLISHED:
      handlePublish()
      break
    case EDITOR_EVENTS.SITE_FIRST_SAVE:
      handleFirstSave(eventPayload || {})
      break
    default:
      break
  }
}

const editorReady = async (editorSDK, appDefinitionId, { firstInstall, origin }) => {
  const msid = await editorSDK.info.getMetaSiteId(appDefinitionId)
  const siteId = await editorSDK.info.getSiteId(appDefinitionId)
  fetchSiteId.resolveData(siteId)
  const withPreset = _.get(origin, 'info.preset')

  Raven.setUserContext({ id: `msid_${msid}` })
  if (!isInitialized && (firstInstall || !origin)) {
    await initApp({ appDefinitionId, editorSDK, msid })
    isInitialized = true
  }
  return startApp(withPreset, origin)
}

module.exports = _.mapValues({ onEvent, editorReady, getAppManifest }, f => Raven.wrap(f))

import * as _ from 'lodash'
import { isLocalhost } from '../../utils/utils'
import * as axios from 'axios'
import { wixAxiosInstanceConfig } from 'wix-axios-config'

const formsBaseUrl = () => (isLocalhost() ? 'localhost:3001' : 'editor.wix.com/wix-form-builder')
const hasQuery = (uri: string) => _.includes(uri, '?')
const getContactsUrl = () => `https://${formsBaseUrl()}/contacts`

const FORMS_URL = 'https://editor.wix.com/_api/wix-form-builder-web'
const PLATFORMIZED_FORMS_URL = `${FORMS_URL}/v1`

export default class RemoteApi {
  private boundEditorSDK
  private appDefinitionId
  private platformizedAxiosInstance

  constructor({ boundEditorSDK, appDefinitionId, appInstance }) {
    this.boundEditorSDK = boundEditorSDK
    this.appDefinitionId = appDefinitionId
    this.platformizedAxiosInstance = wixAxiosInstanceConfig(axios, {
      baseURL: '/',
      req: { headers: { Authorization: appInstance } },
    })
  }

  public getOwnerEmail = async () => this._get(getContactsUrl(), 'get-owner-email')
  public getEmailById = async emailId => this._get(getContactsUrl(), `get-email?emailId=${emailId}`)
  public getTags = async () => this._get(getContactsUrl(), 'get-tags')
  public getCustomFields = async () => this._get(getContactsUrl(), 'get-custom-fields')

  public insertEmail = async email => this._post(getContactsUrl(), 'insert-email', { email })
  public createCustomField = async field =>
    this._post(getContactsUrl(), 'create-custom-field', { field })
  public publishSite = data => this._post(FORMS_URL, 'publish-site', data)
  public editDraft = form =>
    this._platformizedPost(PLATFORMIZED_FORMS_URL, 'edit-draft', { form: form })
  public getPremiumRestrictions = () => this._get(FORMS_URL, 'premium/restrictions')

  private _get = (url, endpoint) => this._request({ url, endpoint, method: 'GET' })
  private _post = (url, endpoint, data) => this._request({ url, endpoint, method: 'POST', data })

  private _platformizedPost = (url, endpoint, data) =>
    this.platformizedAxiosInstance.post(`${url}/${endpoint}`, data)

  private async _request({ url, endpoint, method, data = undefined }) {
    const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const response = !isTemplate
      ? await axios.request({
          headers: { 'X-Wix-With-Static-Templates': 'true' },
          method,
          url: `${url}/${endpoint}${hasQuery(endpoint) ? '&' : '?'}instance=${appInstance}`,
          data,
        })
      : null
    return _.get(response, 'data')
  }
}

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("_"));
	else if(typeof define === 'function' && define.amd)
		define("wix-form-builder", ["_"], factory);
	else if(typeof exports === 'object')
		exports["wix-form-builder"] = factory(require("_"));
	else
		root["wix-form-builder"] = factory(root["_"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__11__) {
return 
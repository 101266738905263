import { BillingPanelReferrer } from '../constants/premium'
import { getApi } from './editor-ready/editor-ready'
import * as _ from 'lodash'
import { getBiLogger, getPanelDefinitions } from './editor-app'
import { EVENTS } from '../constants/bi'

export const openAddFormPanel = async () => {
  const api = await getApi()
  api.managePanels.openAddFormPanel(getPanelDefinitions()) // no await, reolved when panel is closed
  getBiLogger().log({ evid: EVENTS.PANELS.addFormPanel.OPEN_PANEL })
}

export const openComponentPanel = async (componentRef, panelName, onOpen = _.noop) => {
  const api = await getApi()
  api.managePanels.openComponentPanel(componentRef, panelName, getPanelDefinitions(), onOpen)
}

export const openPremiumBillingPanel = async componentRef => {
  const api = await getApi()
  api.managePanels.openPremiumBillingPanel(componentRef, { referrer: BillingPanelReferrer.GFPP })
}

export const addForm = async (
  preset,
  { targetPageRef = null, coords = null, theme = null, source_name = null } = {}
) => {
  const api = await getApi()
  api.addForm.addForm(preset, { targetPageRef, coords, theme, source_name })
}

import * as _ from 'lodash'
import { ROLE_FORM, ROLE_MESSAGE } from '../../../constants/roles'
import { applyStyle } from '../preset/preset-styles'
import { getFormPreset, getFieldPreset } from '../preset/preset-service'
import { getFieldCompStyleProps } from './form-style-service'
import { getHiddenMessagePreset, getSubmitButtonPreset } from '../components/form-structures'
import translations from './translations'
import { escapeRegExp, innerText } from '../../../utils/utils'
import { SuccessActionTypes, SecondsToResetDefaults } from '../../../constants/success-settings'
import { Theme } from '../../../constants/form-style'

const convertToInnerStructure = ({ role, connectionConfig, ...rest }: any) => ({
  role,
  connectionConfig,
  data: rest,
})

const convertFormComponents = formComponent => {
  return {
    box: convertToInnerStructure(_.omit(formComponent, 'components')),
    fields: formComponent.components.map(convertToInnerStructure),
  }
}

const getConnectionConfig = (presetKey, comp) => {
  if (comp.role !== ROLE_FORM) {
    return {}
  }
  return {
    preset: presetKey,
    labels: ['contacts-contacted_me'],
    errorMessage: translations.t('settings.errorMessage.default'),
    secondsToResetForm: SecondsToResetDefaults.MIN,
    successActionType: SuccessActionTypes.SHOW_MESSAGE,
    styles: {
      input: {
        bg: 'color_11',
        bge: 'color_11',
        bgf: 'color_11',
        bgh: 'color_11',
        brd: 'color_15',
        brde: '#f60419',
        brdf: 'color_18',
        brdh: 'color_15',
        fnt: 'font_8',
        txt: 'color_15',
        txt2: 'color_14',
      },
      box: { bg: 'color_11' },
    },
  }
}

export const getExtraMessageText = (data, newSuccessMessage?) => ({
  text: data.text.replace(
    new RegExp(`>${escapeRegExp(innerText(data.text))}`),
    `>${newSuccessMessage || translations.t('settings.successMessage.default')}` // add > to prevent message like span
  ),
})

const getChildComponents = (presetKey, comp, theme) =>
  comp.components &&
  comp.components.map(childComp => deConstructComponent(presetKey, childComp, theme))

const deConstructComponent = (presetKey, rawComp, theme = Theme.THEME01) => {
  const comp = applyStyle(rawComp, theme)
  comp.connectionConfig = _.merge(
    {},
    comp.config,
    getConnectionConfig(presetKey, comp),
    comp.connectionConfig
  )
  if (comp.role === ROLE_MESSAGE) {
    comp.data = _.merge({}, comp.data, getExtraMessageText(comp.data))
  }
  comp.components = getChildComponents(presetKey, comp, theme)
  return comp
}

// return { box, fields }
export const createForm = (presetKey, { coords = { x: 175 }, theme = Theme.THEME01 } = {}) => {
  const rawPreset = getFormPreset(presetKey)
  const formComponent = _.merge({}, deConstructComponent(presetKey, rawPreset, theme), {
    layout: coords || { x: 175 },
  })
  delete formComponent.layout.y
  return convertFormComponents(formComponent)
}

// return { role, connectionConfig, data }
export const createField = (
  presetKey,
  { fieldType, extraData, commonStyles, theme = Theme.THEME01 },
  layout
) => {
  // TODO remove presetKey
  const rawPreset = getFieldPreset(fieldType, extraData)
  const fieldComponent = _.merge({}, deConstructComponent(presetKey, rawPreset, theme), { layout })
  if (!theme) {
    _.assign(fieldComponent.style.style.properties, getFieldCompStyleProps(commonStyles))
  }
  return convertToInnerStructure(fieldComponent)
}

// return { role, connectionConfig, data }
export const createSubmitButton = (layout, theme = Theme.THEME01) => {
  const rawPreset = getSubmitButtonPreset()
  const data = { label: translations.t('preset.submitButtonLabel') }
  const buttonComponent = _.merge({}, deConstructComponent('', rawPreset, theme), { layout, data })
  return convertToInnerStructure(buttonComponent)
}

// return { role, connectionConfig, data }
export const createHiddenMessage = layout => {
  const rawPreset = getHiddenMessagePreset()
  const hiddenMessageComponent = _.merge({}, deConstructComponent('', rawPreset), { layout })
  return convertToInnerStructure(hiddenMessageComponent)
}

import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { Field } from '../../field-types-data'

export const makeLinkToLogin = (): Field => {
  return {
    collectionFieldType: false,
    componentType: COMPONENT_TYPES.RICH_TEXT,
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      data: {
        text: `<p class="font_8"><span><p class="font_7">Already a member? <span style="text-decoration:underline">Click here</span> to login</p></span></p>`,
      },
    },
  }
}

export const preset = t => ({
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 510,
        height: 33,
        x: 60,
        y: 35,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
            f10: 'font_10',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
            f10: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'title_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        text: `<h5 class='font_5'><span style='font-size:30px;'>${t(
          'preset.customerSatisfactionTitleText'
        )}</span></h5>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 510,
        height: 21,
        x: 60,
        y: 658,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f10: 'font_10',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f10: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'message_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        text: `<p class="font_8"><span style="color:#8FCA8F;">${t(
          'preset.successMessageText'
        )}</span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      layout: {
        width: 142,
        height: 40,
        x: 60,
        y: 608,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatp7sl31',
      componentType: 'wysiwyg.viewer.components.SiteButton',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jatp7sl32',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brdh': '0',
            'alpha-txt': '1',
            'alpha-txth': '1',
            bg: 'color_18',
            bgh: 'color_17',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_14',
            brdh: 'color_12',
            brw: '0',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_11',
            txth: 'color_11',
          },
          propertiesSource: {
            'alpha-brdh': 'value',
            bg: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brdh: 'theme',
            brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txth: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
      },
      role: 'button_Role',
      config: {},
      props: {
        type: 'ButtonProperties',
        metaData: { schemaVersion: '1.0' },
        align: 'center',
        margin: 0,
      },
      data: {
        type: 'LinkableButton',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        label: t('preset.submitButtonLabel'),

        link: {
          type: 'FormSubmitButtonLink',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 330,
        y: 103,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatp7slf1',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jav4b0hm',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.lastNameCrmLabel'),

        fieldType: 'lastName',
        crmType: 'lastName',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.lastNameCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'text',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 186,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatp7slq',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jauswed3',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.emailCrmLabel'),
        fieldType: 'email',
        crmType: 'email',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: true,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.emailCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'email',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 103,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatp7sm3',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jav48iz2',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.firstNameCrmLabel'),

        fieldType: 'firstName',
        crmType: 'firstName',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.firstNameCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'text',
      },
    },
    {
      type: 'Component',
      skin: 'TextAreaDefaultSkin',
      layout: {
        width: 510,
        height: 126,
        x: 60,
        y: 442,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatp7smi',
      componentType: 'wysiwyg.viewer.components.inputs.TextAreaInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jauswkau',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'rgba(255, 255, 255, 1)',
            bg2: '#aaaaaa',
            bgd: '#FFFFFF',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            brwd: '0',
            brwf: '1',
            brwh: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextAreaInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'TextAreaDefaultSkin',
      },
      role: 'field_role_text_area',
      config: {
        crmLabel: t('preset.tellUsMoreTextBoxCrmLabel'),

        fieldType: 'generalTextBox',
        crmType: 'customField',
        crmTag: 'main',
      },
      props: {
        type: 'TextAreaInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        textAlignment: 'left',
        textPadding: 16,
        placeholder: t('preset.tellUsMoreTextBoxCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextAreaInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
      },
    },
    {
      type: 'Component',
      skin: 'skins.input.RadioGroupDefaultSkin',
      layout: {
        width: 240,
        height: 88,
        x: 60,
        y: 273,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatp7sn11',
      componentType: 'wysiwyg.viewer.components.inputs.RadioGroup',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jaus9sk8',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bg2h': '1',
            'alpha-bg3': '0.55',
            'alpha-bg3e': '0',
            'alpha-bg3h': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-border_color_focus': '1',
            'alpha-brd': '0.2',
            'alpha-brde': '0.6',
            'alpha-brdf': '0.6',
            'alpha-brdh': '0.4',
            bg: 'color_11',
            bg2: 'color_18',
            bg2d: '#919191',
            bg2h: 'color_18',
            bg3: 'color_15',
            bg3e: '#6EB7FD',
            bg3f: '#00A6FF',
            bg3h: 'color_18',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            border_color_focus: 'color_18',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_18',
            brw: '1',
            brwd: '0',
            brwf: '1',
            brwh: '1',
            fnt: 'font_8',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_13',
            fnt2: 'font_8',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bg2h': 'value',
            'alpha-bg3': 'value',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'theme',
            'alpha-brde': 'theme',
            'alpha-brdf': 'theme',
            'alpha-brdh': 'theme',
            bg: 'theme',
            bg2: 'theme',
            bg2h: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.RadioGroup',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'skins.input.RadioGroupDefaultSkin',
      },
      role: 'field_role_radio_list',
      config: {
        crmLabel: t('preset.experienceRadioButtonCrmLabel'),

        fieldType: 'generalRadioButton',
        crmType: 'customField',
        customFieldId: '',
      },
      props: {
        type: 'RadioGroupProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        alignment: 'left',
        layout: 'vertical',
        buttonsMargin: 17,
        spacing: 16,
        buttonSize: 18,
        labelMargin: 24,
      },
      data: {
        type: 'RadioGroup',
        label: t('preset.experienceStyledText'),

        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: 'Good',
        options: [
          {
            type: 'RadioButton',
            metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
            value: t('preset.testimonialRateRadioButtonGoodValue'),
            label: t('preset.testimonialRateRadioButtonGoodValue'),
          },
          {
            type: 'RadioButton',
            metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
            value: 'Good',
            label: 'Good',
          },
          {
            type: 'RadioButton',
            metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
            value: t('preset.testimonialRateRadioButtonDisappointingValue'),
            label: t('preset.testimonialRateRadioButtonDisappointingValue'),
          },
        ],
        defaultValue: 'Good',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  layout: {
    width: 630,
    height: 699,
    x: 175,
    y: 15,
    scale: 1.0,
    rotationInDegrees: 0.0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.FormContainer',
  role: 'form_Role',
  config: { preset: 'contact-form', labels: ['contacts-contacted_me'], emailId: '' },
})
